import React from "react";
import { Header, Loader } from "semantic-ui-react";

import "./Waiting.css";

const ProjectCard = () => {
    return (
        <div className="main-waiting">
            <div style={{ minWidth: "8rem", minHeight: "8rem" }}>
                <Loader active indeterminate size="massive" />
            </div>
            <Header as="h1" size="huge">
                Something is underway...
            </Header>
        </div>
    );
};

export default ProjectCard;

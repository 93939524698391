import React from "react";

import Waiting from "components/Waiting/Waiting";
import "semantic-ui-css/semantic.min.css";
import "./App.css";

const App = () => {
    // const [activeTab, setActiveTab] = useState("Accueil");

    return (
        <>
            {/* <div className="background"></div> */}
            <div className="App">
                <Waiting />
                {/* <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
                <Main setActiveTab={setActiveTab} /> */}
            </div>
        </>
    );
};

export default App;
